//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiResSearch } from "@/api/apiResSearch";
import localStorage from "@/storage/localStorage";
import { apiHome } from "@/api/apiHome";
import cookie from "@/storage/cookies.js";
export default {
  name: "leaseList",
  components: {
    RentOut: () => import("./component/lease/rentOut"),
    RentEnter: () => import("./component/lease/rentEnter"),
    SearchForm: () => import("@/pages/Search/components/searchNavBar/index.vue")
  },
  props: {},
  data() {
    return {
      activeIndex: "1",
      list: [],
      currentPage: 1,
      pageSizeOut: 40,
      pageSizeEnter: 20,
      total: 0,
      searchPramas: "",
      loading: true,
      isEmpty: false,
      cate: [],
      TYPE_LIST: {},
      form: {
        firstCategoryNo: "",
        type: "",
        sortWay: "-1"
      },
      tabText: { out: "出租信息", put: "求租信息" },
      searchList: [
        {
          title: "类目",
          formKey: "type",
          slist: [],
          hideSearchItem: false
        },
        {
          title: "分类",
          formKey: "firstCategoryNo",
          slist: []
        }
      ],
      sortType: [
        {
          name: "综合排序",
          choice: false,
          current: true,
          chooseType: "sumScoreSort",
          onlyRead: true,
          value: "1"
        },
        {
          name: "发布时间排序",
          choice: true,
          current: false,
          chooseType: "sortWay",
          onlyRead: true,
          value: "1"
        },
        {
          name: "价格排序",
          choice: true,
          current: false,
          chooseType: "priceSort",
          value: "1"
        },
        {
          name: "距离排序",
          choice: true,
          current: true,
          chooseType: "distanceSort",
          onlyRead: true,
          value: "1"
        }
      ],
      priceconf: {
        negotiable: false, //  不看面议  1
        companyNos: false //  平台自营  1
      },
      sortTypeForm: {
        sumScoreSort: "1",
        sortWay: "",
        priceSort: "",
        distanceSort: ""
      },
      currentIndex: 0,
      isSubmitSeachIng: false
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.$bus.$emit('searchTabReset')
    this.getCateList();
  },
  mounted() {
    this.$nextTick(() => {
      if (process.client) {
        this.$bus.$emit("searchTabReset");
        this.$bus.$off("submitSearch").$on("submitSearch", () => {
          // this.handleCurrentChange(1);
        });
        this.handleCurrentChange();
      }
      window.addEventListener("scroll", this.scroll, true);
    });
  },
  methods: {
    // 获取公司companyNos信息
    async getCompanyNosInfo() {
      const res = await apiHome.getCompanyNos();
      const { data, code } = res.data;
      if (+code == 200) {
        const companyNos = data;
        return companyNos;
      }
    },
    chooseSort(item, index) {
      if (!item.onlyRead) {
        item.current = !item.current;
        if (item.chooseType == "priceSort") {
          item.value = item.current ? "1" : "2";
        }
      }
      for (let k in this.sortTypeForm) {
        this.sortTypeForm[k] = "";
      }
      this.sortTypeForm[item.chooseType] = item.value;
      this.currentIndex = index;
      console.log(this.sortTypeForm);
      this.handleCurrentChange(1);
    },
    /**@name 监听滚动事件 */
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          window.innerHeight;
        if (
          bottomOfWindow < 400 &&
          !this.loading &&
          this.list.length < this.total
        ) {
          this.currentPage++;
          this.getLeaseList();
        }
      };
    },
    // 点击tab栏切换
    handleClick(tab, event) {
      const { label, name } = tab;
      // if(this.activeIndex == name) return
      this.activeIndex = name;
      this.cate = [];
      this.list = [];
      this.total = 0;
      this.TYPE_LIST = {};
      this.form.firstCategoryNo = "";
      this.form.type = "";
      this.form.sortWay = 1;
      if (+this.activeIndex === 2) {
        this.form.sortWay = "-1";
      }
      this.handleCurrentChange(1);
    },
    paramsChange(data) {
      console.log("paramsChange", data);
      if (this.isSubmitSeachIng) {
        return;
      }
      if (!data.type) {
        if (data.firstCategoryNo) {
          this.$refs["SearchForm"].delSearchItem({
            formKey: "firstCategoryNo",
            isNoEmit: true
          });
        }
        this.searchList.forEach(item => {
          if (item.formKey === "firstCategoryNo") {
            item.hideSearchItem = true;
          }
        });
      } else {
        this.searchList.forEach(item => {
          if (item.formKey === "firstCategoryNo") {
            item.hideSearchItem = false;
          }
        });
      }
      if (data["firstCategoryNo"]) {
        this.searchList.forEach(item => {
          if (item.formKey === "type") {
            item.hideSearchItem = true;
          }
        });
      } else {
        this.searchList.forEach(item => {
          if (item.formKey === "type") {
            item.hideSearchItem = false;
          }
        });
      }
      this.form = {
        ...this.form,
        ...data
      };
      this.list = [];
      this.handleCurrentChange(1);
    },
    async handleCurrentChange(val = 1) {
      this.list = [];
      let companyNos = [];
      this.currentPage = val;
      if (this.priceconf.companyNos) {
        companyNos = await this.getCompanyNosInfo();
      }
      // this.priceconf.negotiable = this.priceconf.negotiable ? 0 : ''
      this.getClassList();
      this.getLeaseList(companyNos);
    },
    // 获取出租信息一级分类
    async getCateList() {
      const parmas = {
        areacode: this.form.provinceNo,
        keyword: localStorage.read("search_query") || "",
        provinceNo: this.form.provinceNo,
        rentType: this.activeIndex
      };
      const res = await apiResSearch.getCateList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        data.forEach(item => {
          this.TYPE_LIST[item.type] = item.name;
        });
        this.searchList.forEach(item => {
          if (item.formKey === "type") {
            item.slist = data.map(item => {
              return {
                name: item.name,
                no: +item.type === 0 ? "" : item.type
              };
            });
          }
        });
      }
    },
    // 获取出租信息二级分类
    async getClassList() {
      const parmas = {
        areacode: 650000,
        grade: "",
        parentNo: "",
        type: this.form.type
      };
      const res = await apiResSearch.getClassList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        this.searchList.forEach(item => {
          if (item.formKey === "firstCategoryNo") {
            item.slist = data.map(item => {
              return {
                name: item.name,
                no: item.no
              };
            });
          }
        });
      }
    },
    async getLeaseList(companyNos = []) {
      this.loading = true;
      this.searchPramas = localStorage.read("search_query");
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        type,
        firstCategoryNo,
        sortWay
      } = this.form;
      let negotiable = this.priceconf.negotiable ? 0 : "";
      const ip_pos = localStorage.read("positionHome")
        ? JSON.parse(localStorage.read("positionHome"))
        : {};
      const parmas = {
        rentType: this.activeIndex,
        sortWay: sortWay,
        areaNos: cDisList,
        provinceNo: provinceNo,
        cityNo: cityNo,
        firstCategoryNo: firstCategoryNo,
        type: type,
        keyword: localStorage.read("search_query") || "",
        current: this.currentPage,
        limit: this.activeIndex == "1" ? this.pageSizeOut : this.pageSizeEnter,
        longitude: this.activeIndex == 1 ? +ip_pos.lng : "",
        latitude: this.activeIndex == 1 ? +ip_pos.lat : "",
        title: localStorage.read("search_query") || ""
      };
      let paramsData = Object.assign(
        parmas,
        +this.activeIndex === 1 && this.sortTypeForm,
        { negotiable },
        { companyNos: companyNos }
      );
      let res = await apiResSearch.getSearchList(paramsData);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.total = data.total;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.loading = false;
      }
      this.loading = false;
    },
    setCategry(resData) {
      this.cate = resData.dataList.map(x => ({
        no: +x.type === 0 ? "" : x.type,
        name: `${x.name}(${x.num})`
      }));
      this.searchList.forEach(item => {
        if (item.formKey === "type") {
          item.slist = this.cate;
        }
      });
    }
  }
};
