//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "labourList",
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    // this.$bus.$emit('searchTabReset')
  },
  mounted() {
   if (process.client) {
       this.$bus.$emit("searchTabReset");
       this.$bus.$off('submitSearch').$on('submitSearch', ()=>{
          this.handleCurrentChange();
       })
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
