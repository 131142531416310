//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "../../../../env.js";
import { apiResSearch } from "@/api/apiResSearch";
import cookie from "@/storage/cookies.js";
import localStorage from "@/storage/localStorage";
export default {
  name: "goodsList",
  components: {
    NoData: () => import("@/pages/Home/components/noData.vue"),
    SearchForm: () => import("@/pages/Search/components/searchNavBar/index.vue")
  },
  props: {},
  data() {
    return {
      defaultUrl: require("@/assets/url_empty.png"),
      list: [],
      currentPage: 1,
      pageSize: 40,
      total: 0,
      loading: true,
      isEmpty: false,
      isReset: true, // 是否重新出发搜索声明周期钩子
      categoryList: [], // 分类列表
      form: {
        item_cid: "",
        customListId: [],
        brandName: ""
      },
      currentIndex: "",
      searchList: [
        {
          title: "品名",
          formKey: "item_cid",
          slist: []
        },
        {
          title: "筛选",
          formKey: "customListId",
          slist: [],
          isSelectDom: true
        },
        {
          title: "品牌",
          formKey: "brandName",
          slist: [],
          isSelectDom: false
        }
      ],
      sortType: [
        {
          name: "距离",
          choice: false,
          current: false,
          chooseType: "distanceAsc"
        },
        {
          name: "价格",
          choice: true,
          current: false,
          chooseType: "priceAsc"
        },
        {
          name: "更新时间",
          choice: true,
          current: false,
          chooseType: "timeAsc"
        }
      ],
      priceconf: {
        selfSupportTag: false, //  自营  1
        manufacturerTag: false, //  直销  1
        supplierTag: false, //  代理  1
        promoteTag: false //  促销  1
      },
      sortTypeForm: {}
    };
  },
  computed: {
    getIpAddres() {
      let isHasIpAddress = this.$store.state.isHasIpAddress;
      let currentIpAddress =
        JSON.parse(window.sessionStorage.getItem("currentIpAddress")) || {};
      let { lat, lng } = currentIpAddress;
      return {
        isHasIpAddress,
        lat,
        lng
      };
    }
  },
  watch: {},
  created() {},
  mounted() {
    if (process.client) {
      this.$bus.$emit("searchTabReset");
      this.$bus.$off("submitSearch").$on("submitSearch", () => {
        // this.handleCurrentChange();
      });
      this.handleCurrentChange();
    }
    window.addEventListener("scroll", this.scroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, false);
  },
  filters: {
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null || isNaN(datetime)) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  methods: {
    // 下拉翻页加载数据
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          window.innerHeight;
        if (
          bottomOfWindow < 400 &&
          !this.loading &&
          this.list.length < this.total
        ) {
          // console.log("我是否触发");
          this.currentPage++;
          this.getList();
        }
      };
    },
    // 跳转详情
    go2Detail(data) {
      const { commodity, id, type } = data;
      const url = env[process.env.environment].VUE_APP_MATERIALSURL;
      window.open(`${url}/shopdetails/${commodity}?skuId=${id}&type=${type}`);
    },
    paramsChange(data) {
      console.log("params", data);
      this.form = data;
      if (!data.item_cid && data.customListId.length > 0) {
        this.searchList[1]["slist"] = [];
        this.form.customListId = [];
      } else {
        this.searchList[1]["slist"] = [];
      }
      console.log("data", data.brandName);
      if (data.brandName != "") {
        // this.searchList[2]["slist"] = [];
        this.searchList[2]["hideSearchItem"] = true;
      } else {
        this.searchList[2]["hideSearchItem"] = false;
      }
      this.getFilterParamVales(data.item_cid);
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val = 1) {
      this.list = [];
      this.loading = true;
      console.log("valvalval", val);
      this.currentPage = val;
      this.getList();
    },
    async getList() {
      let {
        cDisList = [],
        allCityArea = [],
        cityNo = "",
        provinceNo = "",
        item_cid,
        customListId,
        brandName
      } = this.form;
      cDisList = cDisList.length ? cDisList.join(",") : "";
      allCityArea = allCityArea.length ? allCityArea.join(",") : "";
      const ip_pos = JSON.parse(
        window.sessionStorage.getItem("currentIpAddress")
      ) || {
        lng: 116.405289,
        lat: 39.904987,
        address: { province: "北京" }
      };
      const parmas = {
        brandIds: [],
        customListId: [],
        promoteStatus: "",
        item_areaId: cityNo ? cityNo * 1 : "",
        itemCid: item_cid,
        itemProvinceId: provinceNo ? provinceNo * 1 : "",
        itemDistrictIds: cDisList ? cDisList : allCityArea,
        page: this.currentPage,
        limit: this.pageSize,
        keyWord: localStorage.read("search_query"),
        lat: +ip_pos.lat,
        lon: +ip_pos.lng,
        meters: "pc",
        customListId,
        brandName,
        itemCityId: cityNo
      };
      let { isHasIpAddress, lat, lng } = this.getIpAddres;
      if (isHasIpAddress) {
        parmas.lon = lng;
        parmas.lat = lat;
      }
      let obj = {};
      for (let k in this.priceconf) {
        obj[k] = this.priceconf[k] ? "1" : "";
      }
      this.loading = true;
      try {
        let res = await apiResSearch.searchGoods({
          ...parmas,
          ...obj,
          ...this.sortTypeForm
        });
        console.log("res", res);
        const { data, code, total, msg } = res.data;
        if (+code === 0) {
          const list = data || [];
          this.setCategry(data.categories);
          this.brandNameLlist(data.brands);
          const newArr = list.goods.map(item => {
            const { category, list: arr } = item;
            let attrs = "";
            const a = arr.sort((a, b) => b - a);
            a &&
              a.forEach(el => {
                attrs += `/${el.name}:${el.val}`;
              });
            return {
              ...item,
              newPrice: `${item.money}`,
              newAttrs: `${item.pro.cartTitle}${attrs}`,
              newSupplierName: item.pro.supplierName,
              newStorageName: item.pro.storageName,
              newBrandName: item.pro.brandName,
              newPic: `https://shigongbang.obs.cn-east-3.myhuaweicloud.com/${item.pro.imgList[0]}`
            };
          });
          this.total = total > 999 ? 1000 : total;
          this.list = this.list.concat(newArr);
          this.isEmpty = this.list.length === 0;
          this.loading = false;
          return;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    brandNameLlist(brandNamelist) {
      // brandId
      // brandName
      this.totalSum = 0;
      const cate = brandNamelist.map(item => {
        this.totalSum += item.count;
        return {
          no: `${item.brandName}`,
          name: `${item.brandName}`
        };
      });
      if (!brandNamelist.length) {
        this.searchList[2]["slist"] = [];
        return;
      }
      let categoryList = [{ no: "", name: `不限` }, ...cate];
      this.searchList.forEach(item => {
        if (item.formKey === "brandName") {
          item.slist = categoryList;
        }
      });
    },
    setCategry(cateList) {
      // this.isReset = false
      this.totalSum = 0;
      const cate = cateList.map(item => {
        this.totalSum += item.count;
        return {
          ...item,
          no: item.id,
          name: `${item.name}(${item.count})`
        };
      });
      // { no: "", name: `不限(${this.totalSum})` },
      this.categoryList = [...cate];
      this.searchList.forEach(item => {
        if (item.formKey === "item_cid") {
          item.slist = this.categoryList;
        }
      });
    },
    async getFilterParamVales(id = "") {
      if (id) {
        let res = await apiResSearch.getFilterParamVales(id);
        let data =
          res.data.data.filter(item => {
            if (item.data && item.data.length > 0) {
              return item;
            }
          }) || [];
        this.searchList[1].slist = data;
      }
      this.searchList[0]["hideSearchItem"] = id ? true : false;
    },
    chooseSort(item, index) {
      if (this.loading) {
        return;
      }
      if (this.currentIndex && this.currentIndex != index) {
        this.$set(this.sortType[this.currentIndex], "current", false);
      }
      item.current = !item.current;
      this.currentIndex = index;
      let obj = this.sortType[this.currentIndex];
      this.sortTypeForm = {};
      this.$set(this.sortTypeForm, [obj.chooseType], obj.current);
      this.list = [];
      this.currentPage = 1;
      this.getList();
    }
  }
};
