//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiResSearch } from "@/api/apiResSearch";
import { apiHome } from "@/api/apiHome";
import localStorage from "@/storage/localStorage";
export default {
  name: "logisticsList",
  components: {
    FindGoods: () => import("./component/logistics/findGoods"),
    FindCar: () => import("./component/logistics/findCar")
  },
  props: {},
  data() {
    return {
      activeName: "1",
      isReload: true,
      tabText: {
        out: "找货",
        put: "找车"
      },
      allAreaList: [],
      target: [],
      start: [],
      props: {
        value: "id",
        label: "name",
        children: "children"
      },
      startObj: {
        provinceNo: "",
        cityNo: "",
        districtNo: ""
      },
      targetObj: {
        destProvinceNo: "",
        destCityNo: "",
        destDistrictNo: ""
      },
      reload: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getAllAreaListAdmin();
  },
  mounted() {
    if (process.client) {
      this.$bus.$emit("searchTabReset");
      this.$bus.$off("submitSearch").$on("submitSearch", () => {
        +this.activeName === 1 && this.getLeaseList();
        +this.activeName === 2 && this.getFindDriver();
        this.isReload = false;
        setTimeout(() => {
          this.isReload = true;
        }, 0);
      });
    }
    this.$nextTick(() => {
      this.getLeaseList();
      this.getFindDriver();
    });
  },
  methods: {
    getList() {
      this.$refs["FindGoods"].handleCurrentChange(1);
      +this.activeName === 1 && this.getLeaseList();
      +this.activeName === 2 && this.getFindDriver();
    },
    async getAllAreaListAdmin() {
      let res = await apiHome.getAllAreaListAdmin();
      let { data } = res.data;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        item = this.queryItem(item);
      }
      data.unshift({
        name: "不限",
        id: ""
      });
      this.allAreaList = data;
    },
    queryItem(list) {
      list.children.unshift({
        name: "不限",
        id: ""
      });
      list.children.forEach(item => {
        if (!item.children || item.children.length == 0) {
          delete item.children
          return list;
        } else {
          this.queryItem(item);
        }
      });
    },
    paramsChange(value, key) {
      // this.reload = false;
      if (key == "start") {
        this.start = value;
        if (!value.length) {
          setTimeout(() => {
            this.$refs["startcascader"].$refs.panel.clearCheckedNodes();
          }, 100);
        }
        let list = Object.keys(this.startObj);
        list.forEach((item, index) => {
          this.startObj[item] = value[index] || "";
        });
      } else {
        this.target = value;
        let list = Object.keys(this.targetObj);
        list.forEach((item, index) => {
          this.targetObj[item] = value[index] || "";
        });
      }
      // setTimeout(() => {
      //   this.reload = true;
      // });
    },
    async getFindDriver() {
      const parmas = {
        cityNo: "",
        destCityNo: "",
        destDistrictNo: "",
        destProvinceNo: "",
        districtNo: "",
        provinceNo: "",
        keyWord: localStorage.read("search_query"),
        page: 1,
        limit: 12,
        ...this.startObj,
        ...this.targetObj
      };
      let res = await apiResSearch.getFindDriver(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        this.tabText.put = `找车(${data.totalElements})`;
      }
    },
    async getLeaseList() {
      const parmas = {
        cityNo: "",
        destCityNo: "",
        destDistrictNo: "",
        destProvinceNo: "",
        districtNo: "",
        provinceNo: "",
        keyWord: localStorage.read("search_query"),
        page: 1,
        limit: 10,
        ...this.startObj,
        ...this.targetObj
      };
      let res = await apiResSearch.getFindGoods(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        this.total = data.totalElements > 999 ? 1000 : data.totalElements;
        this.tabText.out = `找货(${data.totalElements})`;
      }
    },
    handleClick(tab, event) {
      this.start = [];
      this.target = [];
      for (let k in this.startObj) {
        this.startObj[k] = "";
      }
      for (let k in this.targetObj) {
        this.targetObj[k] = "";
      }
      +this.activeName === 1 && this.getLeaseList();
      +this.activeName === 2 && this.getFindDriver();
    }
  }
};
