//
//
//
//
//
//
//
//
//
//

import { apiResSearch } from "@/api/apiResSearch";
import localStorage from "@/storage/localStorage";
import cookie from "@/storage/cookies.js";
export default {
  name: "SearchResult",
  data() {
    return {
      tabIndex: 0,
      meneTabFlag: true,
      list: [
        { name: "企业", value: 0, path: "/SearchResult/companyList" },
        { name: "商品", value: 1, path: "/SearchResult/goodsList" },
        { name: "招采", value: 2, path: "/SearchResult/tenderList" },
        { name: "集采", value: 3, path: "/SearchResult/gropbuyList" },
        { name: "租赁", value: 4, path: "/SearchResult/leaseList" },
        // { name: '劳务', value:5, path: '/SearchResult/labourList'},
        { name: "物流", value: 5, path: "/SearchResult/logisticsList" }
      ]
    };
  },
  components: {
    TabList: () => import("./components/tabMenuList")
  },
  props: {},
  watch: {},
  computed: {
    provinceId() {
      return JSON.parse(cookie.cookieRead("area")).id;
    }
  },
  created() {
    if (Object.keys(localStorage).length == 0) {
      return;
    } else {
      this.activeIndex = localStorage.read("activeIndex")
        ? localStorage.read("activeIndex")
        : 0;
    }
    let urlPath = this.$route.path;
    switch (urlPath) {
      case "/SearchResult/product":
        this.activeIndex = 0;
        break;
      case "/SearchResult/lease":
        this.activeIndex = 1;
        break;
      case "/SearchResult/logistics":
        this.activeIndex = 2;
        break;
      case "/SearchResult/enterprise":
        this.activeIndex = 3;
        break;
      default:
        this.activeIndex = 0;
    }
    this.getAllAreaList();
    // this.getAllDataList();
  },
  mounted() {
    this.$bus.$off("searchTabReset").$on("searchTabReset", () => {
      this.meneTabFlag = false;
      // this.tabIndex = 0;
      setTimeout(() => {
        this.meneTabFlag = true;
      });
    });
  },
  methods: {
    /**
     * 搜索菜单
     */
    clickMenuItem(data) {
      this.tabIndex = data.value;
      this.$router.push(data.path);
    },
    //获得所有地区
    getAllAreaList() {
      apiResSearch.getAllAreaList().then(res => {
        if (res.data.code === 200 && res.data.data.length) {
          res.data.data.forEach(item => {
            item.children.forEach(itemIn => {
              itemIn.children && itemIn.children.forEach(itemInIn => {
                delete itemInIn.children;
              });
            });
          });
          this.$store.commit("SET_GLOBALALLAREA", res.data.data);
        }
      });
    },
    //点击tab项
    clickTab(item, index) {
      localStorage.set("activeIndex", index);
      this.activeIndex = index;
      this.$router.push(item.path);
    },
    //获取所有数据
    async getAllDataList() {
      //商品
      var searchKey;
      if (Object.keys(localStorage).length == 0) {
        return;
      } else {
        searchKey = localStorage.read("search_query")
          ? localStorage.read("search_query")
          : "";
      }
      let result = await apiResSearch.searchGoods(searchKey, "", 1, 20, "", "");
      if (result.data && result.status == 200) {
        this.productNumber = result.data.total ? result.data.total : 0;
      }
      //机械租赁
      apiResSearch
        .searchMechanical(
          {
            page: 1,
            limit: 20
          },
          {
            words: searchKey
          }
        )
        .then(result => {
          if (result.data && result.status == 200) {
            this.marNumber = result.data.data.total
              ? result.data.data.total
              : 0;
          } else {
            this.marNumber = 0;
          }
        })
        .catch(() => console.log("promise catch err"));
      //设备租赁
      apiResSearch
        .searchEquipment(
          {
            page: 1,
            limit: 20
          },
          {
            title: searchKey,
            province_no: this.provinceId,
            district_no: []
          }
        )
        .then(res => {
          this.dataLoading = false;
          if (res.data && res.status == 200) {
            this.equNumber = res.data.recordCount ? res.data.recordCount : 0;
          } else {
            this.equNumber = 0;
          }
        })
        .catch(() => console.log("promise catch err"));
      // 找货搜索

      let data = {};
      data.provinceNo = "";
      data.cityNo = "";
      data.districtNo = "";
      data.destProvinceNo = "";
      data.destCityNo = "";
      data.destDistrictNo = "";
      data.page = 1;
      data.limit = 20;
      data.keyWord = searchKey;
      let res = await apiResSearch.searchRentInfoNew(data);
      if (res.data && res.status == 200) {
        this.goodsNumber = res.data.data.totalElements
          ? res.data.data.totalElements
          : 0;
      }

      // 找车搜索
      let dataM = {};
      dataM.keyWord = searchKey;
      dataM.page = 1;
      dataM.limit = 20;
      dataM.provinceNo = "";
      dataM.cityNo = "";
      dataM.districtNo = "";
      dataM.destProvinceNo = "";
      dataM.destCityNo = "";
      dataM.destDistrictNo = "";
      let resM = await apiResSearch.searchRentInfoM(dataM);
      if (resM.data && resM.status == 200) {
        this.carNumber = resM.data.data.totalElements
          ? resM.data.data.totalElements
          : 0;
      }
      //企业
      let resultE = await apiResSearch.searchComList({
        areaNo: "",
        provinceNo: this.provinceId,
        category: "",
        distance: "",
        latitude: "",
        longitude: "",
        registeredCapital: "",
        serviceType: "",
        page: 1,
        limit: 20,
        name: searchKey
      });
      if (resultE.data && resultE.status == 200) {
        this.enterpriseNumber = resultE.data.data.total
          ? resultE.data.data.total
          : 0;
      }
      this.tabList[0].number = this.productNumber;
      this.tabList[3].number = this.enterpriseNumber;
      this.tabList[2].number = this.carNumber + this.goodsNumber;
      this.tabList[1].number = this.marNumber + this.equNumber;
    }
  },
  beforeDestroy() {
    localStorage.set("activeIndex", 0);
  },
  destroyed() {
    // localStorage.clear("activeIndex");
    localStorage.set("activeIndex", 0);
  },
  mixins: [],
  filters: {}
};
