//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiResSearch } from "@/api/apiResSearch";
import localStorage from "@/storage/localStorage";
import {
  groupBuyTypeEnum,
  groupbuyClassEnum,
  groupStateEnum,
  joinTypeEnum,
  offerTypeEnum
} from "@/pages/Search/page/component/groupbuying/constants.js";
export default {
  name: "gropbuyList",
  components: {
    NeedBuy: () => import("./component/groupbuying/needBuy"),
    SupplyBuy: () => import("./component/groupbuying/supplyBuy"),
    SearchForm: () => import("@/pages/Search/components/searchNavBar/index.vue")
  },
  props: {},
  data() {
    return {
      activeIndex: "1",
      list: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      searchPramas: "",
      loading: true,
      isEmpty: false,
      tabText: { need: "需方集采", sup: "供方发起", com: "企业专项" },
      form: {
        groupBuyType: "",
        groupbuyClass: "",
        firstType: "",
        status: "",
        joinType: "",
        offerType: "",
        cDisList: [],
        cityNo: "",
        provinceNo: ""
      },
      // 需方发起form列表
      searchList: [
        {
          title: "集采方式",
          formKey: "groupBuyType",
          slist: groupBuyTypeEnum
        },
        {
          title: "集采类型",
          formKey: "groupbuyClass",
          slist: groupbuyClassEnum
        },
        {
          title: "商品类型",
          formKey: "firstType",
          slist: []
        },
        {
          title: "集采状态",
          formKey: "status",
          slist: groupStateEnum
        }
      ],
      // 供方发起form列表
      saleCategryList: [],
      searchSaleList: [
        {
          title: "商品类型",
          formKey: "firstType",
          slist: []
        },
        {
          title: "集采状态",
          formKey: "status",
          slist: groupStateEnum
        }
      ],
      searchneedList: [
        {
          title: "集采方式",
          formKey: "groupBuyType",
          slist: groupBuyTypeEnum
        },
        {
          title: "集采类型",
          formKey: "groupbuyClass",
          slist: groupbuyClassEnum
        },
        {
          title: "商品类型",
          formKey: "firstType",
          slist: []
        },
        {
          title: "集采状态",
          formKey: "status",
          slist: groupStateEnum
        },
        {
          title: "加盟客户",
          formKey: "joinType",
          slist: joinTypeEnum
        },
        {
          title: "报价客户",
          formKey: "offerType",
          slist: offerTypeEnum
        }
      ],
      groupCount: {
        common: 0,
        member: 0,
        saleCount: 0
      },
      sortType: [
        {
          name: "采购家数",
          choice: true,
          current: true,
          chooseType: "order",
          onlyRead: false,
          value: "1"
        },
        {
          name: "报价家数",
          choice: true,
          current: true,
          chooseType: "order2",
          onlyRead: false,
          value: "1"
        }
      ],
      sortTypeForm: {
        order: ""
      },
      currentIndex: "",
      sortTypeCom: [
        {
          name: "采购家数",
          choice: true,
          current: true,
          chooseType: "order",
          onlyRead: false,
          value: "1"
        },
        {
          name: "报价家数",
          choice: true,
          current: true,
          chooseType: "order2",
          onlyRead: false,
          value: "2"
        },
        {
          name: "截止时间",
          choice: true,
          current: true,
          chooseType: "order3",
          onlyRead: false,
          value: "3"
        }
      ],
      sortTypeFormCom: {
        order: ""
      },
      currentIndexCom: ""
    };
  },
  computed: {},
  watch: {
    searchPramas: {
      handler: async function(val) {
        this.getCount();
        try {
        } catch (error) {}
      },
      immediate: true
    }
  },
  created() {
    // this.$bus.$emit('searchTabReset')
    // if (process.client) {
    //   this.form.allSearchKey = localStorage.getItem("search_query") || "";
    //   this.form.searchKey = localStorage.getItem("search_query") || "";
    // }
  },
  mounted() {
    this.handleCurrentChange(1);
    if (process.client) {
      this.$bus.$emit("searchTabReset");
      this.$bus.$off("submitSearch").$on("submitSearch", () => {
        console.log('我是否触发')
        this.searchPramas = localStorage.read("search_query");
        // this.handleCurrentChange(1);
      });
    }
    this.$nextTick(() => {
      this.searchPramas = localStorage.read("search_query");
    });
    window.addEventListener("scroll", this.scroll, true);
  },
  methods: {
    resetParams() {
      this.currentIndex = "";
      this.currentIndexCom = "";
      console.log("this.activeIndex", this.activeIndex);
      let list =
        +this.activeIndex === 1
          ? this.sortType
          : +this.activeIndex === 3
          ? this.sortTypeCom
          : [];
      for (let k in this.sortTypeForm) {
        this.sortTypeForm[k] = "";
        this.sortTypeFormCom[k] = "";
      }
      for (let i = 0; i < list.length; i++) {
        list[i]["current"] = true;
      }
      this.handleCurrentChange(1);
    },
    chooseSortCom(item, index) {
      if (!item.onlyRead) {
        item.current = !item.current;
        if (item.chooseType == "order") {
          item.value = item.current ? "1+" : "1-";
        } else if (item.chooseType == "order2") {
          item.value = item.current ? "2+" : "2-";
        } else {
          item.value = item.current ? "3+" : "3-";
        }
      }
      for (let k in this.sortTypeForm) {
        this.sortTypeForm[k] = "";
      }
      this.sortTypeFormCom["order"] = item.value;
      this.currentIndexCom = index;
      this.handleCurrentChange(1);
    },
    chooseSort(item, index) {
      if (!item.onlyRead) {
        item.current = !item.current;
        if (item.chooseType == "order") {
          item.value = item.current ? "1+" : "1-";
        } else {
          item.value = item.current ? "2+" : "2-";
        }
      }
      for (let k in this.sortTypeForm) {
        this.sortTypeForm[k] = "";
      }
      this.sortTypeForm["order"] = item.value;
      this.currentIndex = index;
      this.handleCurrentChange(1);
    },
    // 下拉翻页加载数据
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          window.innerHeight;
        if (
          bottomOfWindow < 400 &&
          !this.loading &&
          this.list.length < this.total
        ) {
          this.currentPage++;
          if (this.activeIndex == 1) {
            this.getList();
          } else if (this.activeIndex == 2) {
            this.getGroupSaleList();
          } else {
            this.getList();
          }
        }
      };
    },
    // tab切换触发
    handleClick(tab, event) {
      const { label, name } = tab;
      this.activeIndex = name;
      this.list = [];
      this.total = 0;
      // this.form.groupBuyType = "";
      // this.form.groupbuyClass = "";
      // this.form.firstType = "";
      // this.form.status = "";
      this.handleCurrentChange(1)
    },
    paramsChange(data) {
      console.log('data', data)
      this.list = [];
      this.currentPage = 1;
      this.form = data;
      let list =
        +this.activeIndex === 3 ? this.searchneedList : this.searchList;
      if (this.activeIndex != 2) {
        if (data.groupbuyClass != "") {
          console.log("data.groupbuyClass", data.groupbuyClass);
          let listss = {
            1: "商品类型",
            2: "机械分类",
            3: "设备分类"
          };
          list.forEach(item => {
            if (item.formKey === "firstType") {
              item.title = listss[data.groupbuyClass];
            }
          });
          if (data.firstType == "") {
            list.forEach(item => {
              if (item.formKey === "groupbuyClass") {
                item.hideSearchItem = false;
              }
            });
          } else {
            list.forEach(item => {
              if (item.formKey === "groupbuyClass") {
                item.hideSearchItem = true;
              }
            });
          }
        } else {
          list.forEach(item => {
            if (item.formKey === "groupbuyClass") {
              item.hideSearchItem = false;
            }
          });
          if (data.firstType) {
            this.$refs["SearchForm"].delSearchItem({
              formKey: "firstType",
              isNoEmit: true
            });
          } else {
            list.forEach(item => {
              if (item.formKey === "firstType") {
                item.slist = [];
              }
            });
          }
        }
        list.forEach(item => {
          if (item.formKey === "firstType" && !data.groupbuyClass) {
            item.slist = [];
          }
        });
        if (+data.groupbuyClass === 1) {
          // 商品参数
          this.getGoodsCateList();
        }
        if (+data.groupbuyClass === 2) {
          // 机械租赁
          this.getJxCateList();
        }
        if (+data.groupbuyClass === 3) {
          // 设备租赁
          this.getSbCateList();
        }
      } else {
        this.getGoodsCateList();
      }

      this.handleCurrentChange();
    },
    handleCurrentChange(val = 1) {
      this.list = [];
      // this.getCount()
      if (+this.activeIndex === 1 || +this.activeIndex === 3) {
        this.getList();
        return;
      } else {
        this.getGroupSaleList();
      }
      // !this.saleCategryList.length && this.getGoodsCateList();
    },
    // 设备租赁分类
    async getSbCateList() {
      const res = await apiResSearch.getSbCateList();
      let list =
        +this.activeIndex === 3 ? this.searchneedList : this.searchList;
      const { data, code } = res.data;
      if (+code === 200) {
        let cate = data.map(x => ({
          no: x.no,
          name: x.name
        }));
        const arr = [{ no: "", name: "全部" }, ...cate];
        list.forEach(item => {
          if (item.formKey === "firstType") {
            item.slist = arr;
          }
        });
      }
    },
    // 机械租赁分类
    async getJxCateList() {
      const res = await apiResSearch.getJxCateList();
      let list =
        +this.activeIndex === 3 ? this.searchneedList : this.searchList;
      const { data, code } = res.data;
      if (+code === 200) {
        let cate = data.map(x => ({
          no: x.no,
          name: x.name
        }));
        const arr = [{ no: "", name: "全部" }, ...cate];
        list.forEach(item => {
          if (item.formKey === "firstType") {
            item.slist = [];
            item.slist = arr;
          }
        });
      }
    },
    // 商品材料分类
    async getGoodsCateList() {
      const res = await apiResSearch.getGoodsCateList();
      console.log("getGoodsCateList", res);
      if (res.data.code === 200 && res.data.data && res.data.data.length) {
        let cate = res.data.data.map(x => ({
          no: x.id,
          name: x.name
        }));
        const arr = [{ no: "", name: "全部" }, ...cate];
        // if (+this.activeIndex === 1) {
        this.searchList.forEach(item => {
          if (item.formKey === "firstType") {
            item.slist = arr;
          }
        });
        // return;
        // }
        this.searchSaleList.forEach(item => {
          if (item.formKey === "firstType") {
            item.slist = arr;
          }
        });
        this.searchneedList.forEach(item => {
          if (item.formKey === "firstType") {
            item.slist = arr;
          }
        });
        this.saleCategryList = res.data;
      }
    },
    // 获取需方列表
    async getList() {
      this.loading = true;
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        firstType,
        groupBuyType,
        groupbuyClass,
        status,
        joinType,
        offerType
      } = this.form;
      let parmas = {
        groupbuyMode: 1,
        provinceNo: provinceNo,
        cityNo: cityNo,
        status: status,
        groupbuyClass: groupbuyClass,
        groupBuyType: groupBuyType,
        firstType: firstType,
        allSearchKey: this.searchPramas,
        searchKey: this.searchPramas,
        current: this.currentPage,
        limit: this.pageSize,
        joinType,
        offerType,
        typeNo: firstType || ""
      };
      if (+this.activeIndex === 3) {
        parmas.isNeed = true;
        parmas.groupbuyMode = 2;
      }
      if (+this.activeIndex === 1) {
        parmas = Object.assign(parmas, this.sortTypeForm);
      }
      if (+this.activeIndex === 3) {
        parmas = Object.assign(parmas, this.sortTypeFormCom);
      }
      try {
        const res = await apiResSearch.getGroupList(parmas);
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const list = data.records || [];
          // this.tabText.need = `需方发起`;
          this.total = data.total > 999 ? 1000 : data.total;
          let allList = list.map(item => ({
            ...item,
            detailList: JSON.parse(item.details)
          }));
          this.list = this.list.concat(allList);
          this.isEmpty = this.list.length === 0;
          this.loading = false;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    // 获取供方列表
    async getGroupSaleList() {
      this.loading = true;
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        firstType,
        status
      } = this.form;
      const parmas = {
        provinceNo: provinceNo,
        cityNo: cityNo,
        status: status,
        firstType: firstType,
        searchKey: this.searchPramas,
        current: this.currentPage,
        limit: this.pageSize
      };
      const res = await apiResSearch.getGroupSaleList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        // this.tabText.sup = `供方发起(${data.total})`;
        this.total = data.total > 999 ? 1000 : data.total;
        let allList = list.map(item => ({
          ...item,
          detailList: JSON.parse(item.details)
        }));
        this.list = this.list.concat(allList);
        this.isEmpty = this.list.length === 0;
        this.loading = false;
      }
      this.loading = false;
    },
    async getCount() {
      let params = {
        searchKey: this.searchPramas
      };
      let res = await Promise.all([
        apiResSearch.getgroupCount(params),
        apiResSearch.getgroupCountSaleRecord(params)
      ]);
      // console.log("res", res);
      if (res) {
        let { common, member } = res[0]["data"]["data"];
        let saleCount = res[1]["data"]["data"];
        // console.log("saleCountsaleCountsaleCount", common, member, saleCount);
        this.$nextTick(() => {
          this.groupCount = {
            common: common,
            member: member,
            saleCount: saleCount
          };
        });
      }
    }
  }
};
