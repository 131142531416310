//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "../../../../env.js";
import { apiResSearch } from "@/api/apiResSearch";
import { apiHome } from "@/api/apiHome";
import localStorage from "@/storage/localStorage";
export default {
  name: "companyList",
  components: {
    NoData: () => import("@/pages/Home/components/noData.vue"),
    InquiryLetterDialog: () =>
      import("@/pages/Search/page/component/dialog/InquiryLetterDialog.vue"),
    CompanyTopIM: () =>
      import("@/pages/Search/page/component/dialog/CompanyTopIM.vue"),
    bussinessAction: () =>
      import("@/pages/Search/page/component/dialog/bussinessAction.vue"),
    SearchForm: () => import("@/pages/Search/components/searchNavBar/index.vue")
  },
  data() {
    return {
      isEmpty: false,
      defaultUrl: require("@/assets/company_list_icon.png"),
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      position: null,
      searchPramas: "",
      loading: true,
      //函询弹窗
      inByLetDialogShow: false,
      inquiryType: [],
      companyNo: "",
      companyName: "",
      isImShow: false,
      bussinessActionShow: false,
      bussinessInfo: {},
      form: {
        industryCategory: "",
        industryCategoryType: "",
        provinceNo: "",
        cityNo: "",
        cDisList: []
      },
      searchList: [
        {
          title: "行业分类",
          formKey: "industryCategory",
          slist: [],
          hideSearchItem: false
        },
        {
          title: "分类类型",
          formKey: "industryCategoryType",
          slist: [],
          hideSearchItem: false
        }
      ],
      sortType: [
        {
          name: "综合排序",
          choice: false,
          current: true,
          chooseType: "default"
        },
        {
          name: "入驻时间排序",
          default: false,
          choice: true,
          chooseType: "time"
        },
        {
          name: "信用分排序",
          default: false,
          choice: true,
          chooseType: "score"
        }
      ],
      ruleform: {},
      industryCategoryList: []
    };
  },
  created() {
    let areaAll = JSON.parse(localStorage.read("areaAll")).map(item => {
      return {
        no: item.id,
        name: item.shortName
      };
    });
    if (!areaAll || !areaAll.length) {
    }
    // this.$set(
    //   this.searchList[0],
    //   "slist",
    //   [{ name: "不限", no: "" }].concat(areaAll)
    // );
    this.getIndustryCategoryList();
  },
  mounted() {
    if (process.client) {
      this.$bus.$emit("searchTabReset");
      this.$bus.$off("submitSearch").$on("submitSearch", () => {
        this.position = JSON.parse(localStorage.read("positionHome"));
        this.searchPramas = localStorage.read("search_query");
      });
    }
    this.$nextTick(() => {
      this.position = JSON.parse(localStorage.read("positionHome"));
      this.searchPramas = localStorage.read("search_query");
      this.handleCurrentChange(this.currentPage);
    });
    window.addEventListener("scroll", this.scroll, true);
    // if(){
    //   this.$bus.$emit('submitSearch')
    // }
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, false);
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
    relevance(){
      return this.$store.state.relevance;
    },
  },
  watch: {},
  filters: {
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null || isNaN(datetime)) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  methods: {
    paramsChange(res) {
      let { industryCategory, industryCategoryType } = res;
      // console.log("this.form", res.industryCategory);
      // console.log("this.form", this.form.industryCategory);
      if (industryCategory) {
        // 处理对应二级分类类型筛选展示数据
        let industryCategoryTypeList = this.searchList[0]["slist"].find(
          item => {
            return item.no == industryCategory;
          }
        )["children"];
        let list = industryCategoryTypeList
          ? [{ name: "不限", no: "" }]
              .concat(industryCategoryTypeList)
              .map(item => {
                return {
                  name: item.name,
                  no: item.id || "",
                  parentId: item.parentId || "",
                  children: item.childrenVO || []
                };
              })
          : [];
        this.$set(this.searchList[1], "slist", list);
        if(industryCategoryType){
          this.searchList[0]["hideSearchItem"] = true;
        } else {
          this.searchList[0]["hideSearchItem"] = false;
        }
      } else {
        if (industryCategoryType) {
          this.$refs["searchForm"].delSearchItem({
            industryCategoryType: this.form.industryCategoryType,
            formKey: "industryCategoryType"
          });
          this.searchList[1].slist = [];
          this.searchList[0]["hideSearchItem"] = false;
        } else {
          this.searchList[1].slist = [];
          this.searchList[0]["hideSearchItem"] = false;
        }
      }
      this.currentPage = 1;
      this.list = [];
      this.getComList();
    },
    chooseSort(item) {
      if (this.loading) {
        return;
      }
      // 选择排序方式
      let settlementTimeSort = "";
      let creditScoreSort = "";
      this.sortType.forEach(i => {
        this.$set(i, "current", false);
        if (!item.choice || this.chooseType != item.chooseType) {
          this.$set(i, "default", false);
        }
      });
      this.$set(item, "current", true);
      if (this.chooseType === item.chooseType && item.choice) {
        this.$set(item, "default", !item.default);
      }
      switch (item.chooseType) {
        case "default":
          settlementTimeSort = "";
          creditScoreSort = "";
          break;
        case "time": //入驻时间 1-升序 2-降序
          settlementTimeSort = !item.default ? 2 : 1;
          creditScoreSort = "";
          break;
        case "score":
          settlementTimeSort = "";
          creditScoreSort = !item.default ? 2 : 1;
          break;

        default:
          break;
      }
      let params = {
        settlementTimeSort, //入驻时间 1-升序 2-降序
        creditScoreSort //信用评分 1-升序 2-降序
      };
      this.$set(this, "ruleform", params);
      this.currentPage = 1;
      this.chooseType = item.chooseType;
      this.list = [];
      this.getComList();
    },
    // 函询确认
    confirminDialog(obj) {
      obj.siteNo = this.companyNo;
      obj.receiveDTO.answerUserType = 1;
      obj.receiveDTO.answerCompanyNo = this.companyNo;
      //判断函询身份传不同字段
      // if(userType === 0) {//个人
      //   obj.receiveDTO.answerUserNo = this.companyNo
      // }else if(userType === 1){//企业
      //   obj.receiveDTO.answerCompanyNo = this.companyNo
      // }//团队暂时保留。。。
      obj.filedList = JSON.stringify(obj.filedList);
      let data = { ...obj };
      apiResSearch
        .addInquiry(data)
        .then(res => {
          if (res.data.code === 200) {
            this.$message.success("函询成功！");
            this.inByLetDialogShow = false;
            this.$refs.InBylet.clearForm();
          } else {
            this.$message.error(res.data.msg);
            this.$refs.InBylet.closeLoading();
          }
        })
        .catch(err => {
          if (err.msg) {
            this.$message.error(err.msg);
          } else {
            this.$message.error("函询失败！");
          }
        });
    },
    //获取函询类型
    getInquiryType() {
      apiResSearch.getInquiryType().then(res => {
        if (res.data.code === 200) {
          this.inquiryType = res.data.data;
        } else {
          this.$message.error("函询类型获取失败！");
        }
      });
    },
    // 函询关闭
    closeinDialog(val) {
      this.inByLetDialogShow = val;
      this.$refs.InBylet.clearForm();
    },
    async clickItem(name, data) {
      this.companyNo = data.no;
      this.companyName = data.name;
      const { loginType } = this.$store.state; // 用户是否登录
      if (!loginType) {
        let data = {
          callBack: function() {
            this.$store.dispatch("setUser");
            // this.pushThis();
          }.bind(this)
        };
        this.$_login(data);
        return;
      }
      const { data: res } = await apiHome.getIdentifiers();
      const arr = res.result.identifiers;
      const ApplyMemberC = arr.some(i => i === "ApplyMemberC"); // 是否有申请权限
      const InviteJoinListC = arr.some(i => i === "InviteJoinListC"); // 是否有邀请权限
      let {
        identityType,
        type,
        realName
      } = this.userInfo;
      const isSinglePass = +identityType === 0; // 个人用户
      const isRealPass = realName && realName.length > 0; // 实名认证
      if (name === "申请加盟") {
        if (type == 2 || type == 3) {
          // 个人/企业身份可跳转
          this.$message.warning("请切换为企业/个人身份才可申请加盟！");
        } else if (!isRealPass) {
          // 未实名认证展示实名认证弹窗
          this.$_authUser();
        } else if (type == 1 && !ApplyMemberC) {
          this.$message.warning("您没有权限，请联系管理员开通！");
        } else {
          const url = env[process.env.environment].VUE_APP_HUIYUAN;
          window.open(
            `${url}/joinInfo?name=${data.name}&no=${data.no}&id=${data.id}&type=1&isApproval=0&personal=0`
          );
        }

        return;
      }
      if (name === "邀请加盟") {
        if (type == 2 || type == 3) {
          this.$message.warning("请切换为企业/个人身份才可申请加盟！");
        } else if (!isRealPass) {
          // 未实名认证展示实名认证弹窗
          this.$_authUser();
        } else if (type == 1 && !InviteJoinListC) {
          this.$message.warning("您没有权限，请联系管理员开通！");
        } else {
          const url = env[process.env.environment].VUE_APP_HUIYUAN;
          window.open(
            `${url}/joinInfo?name=${data.name}&no=${data.no}&id=${data.id}&type=0&isApproval=0&personal=0`
          );
        }
        return;
      }
      if (name === "关注企业") {
        if (data.no === this.$store.state.authUser.comNo)
          return this.$message.warning(`不能关注自己的企业！`);
        let isfollow = data.follow;
        const parmas = { businessNo: data.no, type: 0 };
        apiResSearch.userFocus(parmas).then(res => {
          const { code } = res.data;
          if (+code === 200) {
            isfollow
              ? this.$_toast({ type: "info", msg: "取消关注成功" })
              : this.$_toast({ type: "success", msg: "关注成功" });
            this.$set(data, "follow", !isfollow);
          }
        });
        return;
      }
      if (name === "函询") {
        this.getInquiryType();
        this.inByLetDialogShow = true;
        return;
      }
      if (name === "商务活动") {
        console.log('isSinglePass', isSinglePass)
        if (isSinglePass) {
          this.$message.warning("当前身份没有申请商务活动权限，请切换至企业身份下操作！");
        } else {
          this.bussinessActionShow = true;
          // data.no
        }
        return;
      }

      // 交流
      this.isImShow = true;
      setTimeout(() => {
        this.$refs["btn-item-im"] && this.$refs["btn-item-im"].chatToServicer();
      }, 300);
    },
    go2Detail(data) {
      const { id, no } = data;
      const url = env[process.env.environment].VUE_APP_MATERIALSURL;
      window.open(`${url}/company/${id}?no=${no}`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getComList();
    },
    async getComList(obj = {}) {
      this.loading = true;
      const { lat = 0, lng = 0 } = this.position;
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        industryCategory,
        industryCategoryType
      } = this.form;
      const parmas = {
        onMap: 0,
        latitude: lat,
        longitude: lng,
        page: this.currentPage,
        limit: this.pageSize,
        name: this.searchPramas,
        province: this.form.province,
        districtList: cDisList,
        city: cityNo,
        province: provinceNo,
        industryCategory,
        industryCategoryType
      };
      let res = await apiResSearch.homeSearchComList(
        Object.assign(parmas, this.ruleform)
      );
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.total = data.total > 999 ? 1000 : data.total;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.loading = false;
      }
      this.loading = false;
    },
    async bussinessDialog(obj) {
      let res = await apiHome.saveBussinessDetail(obj);
      let { data, code, msg } = res.data;
      if (+code == 200) {
        this.$message.success("申请成功");
        this.bussinessActionShow = false;
        // this.$refs.bussinessAction.clearForm();
      } else {
        this.$message.error(res.data.msg);
        this.$refs.bussinessAction.closeLoading();
      }
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          window.innerHeight;
        if (
          bottomOfWindow < 400 &&
          !this.loading &&
          this.list.length < this.total
        ) {
          this.currentPage++;
          this.getComList();
        }
      };
    },
    async getIndustryCategoryList() {
      let res = await apiResSearch.industryCategoryList();
      console.log("行业分类s", res);
      let data = res.data.data.map(item => {
        return {
          name: item.name,
          no: item.id,
          parentId: item.parentId,
          children: item.childrenVO
        };
      });
      this.searchList[0].slist = [{ name: "不限", no: "" }].concat(data);
    },
    currentItem(data) {
      console.log("res", data);
    },
    validateImage(pathImg) {
      console.log('pathimg', pathImg)
      if(!pathImg){
        return this.defaultUrl;
      }
      try {
          var ImgObj = new Image();
          ImgObj.src = pathImg;
          if(ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
            return pathImg;
          } else {
            return this.defaultUrl;
          }
      } catch (error) {
        return this.defaultUrl;
      }
    }
  }
};
