//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiResSearch } from "@/api/apiResSearch";
import localStorage from "@/storage/localStorage";
export default {
  name: "tenderList",
  data() {
    return {
      activeIndex: "1",
      list: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: true,
      isEmpty: false,
      tabText: { tend: "招标", bid: "竞价", notice: "中标公告" },
      isReqCount: true,
      tenderType: {}, // 招标类型
      tenderMothed: {}, // 招标方式
      tenderState: {},
      form: {
        type: "",
        state: "",
        verticalType: "",
        price: ""
      },
      searchList: [
        {
          title: "招标类型",
          formKey: "type",
          slist: []
        },
        {
          title: "招标状态",
          formKey: "state",
          slist: []
        },
        {
          title: "招标方式",
          formKey: "verticalType",
          slist: []
        },
        {
          title: "概算金额",
          formKey: "price",
          slist: []
        }
      ]
    };
  },
  components: {
    TenderIndex: () =>
      import("@/pages/Search/page/component/tender/tendIndex.vue"),
    Bidding: () => import("@/pages/Search/page/component/tender/bidding.vue"),
    WinbidNotice: () =>
      import("@/pages/Search/page/component/tender/winbidNotice.vue"),
    SearchForm: () => import("@/pages/Search/components/searchNavBar/index.vue")
  },
  computed: {},
  created() {},
  mounted() {
    if (process.client) {
      this.$bus.$emit("searchTabReset");
      let that = this;
      this.$bus.$off("submitSearch").$on("submitSearch", () => {
      //   console.log("我是招采的查询信息", that.form);
      //   this.handleCurrentChange();
      });
      this.handleCurrentChange();
    }
    window.addEventListener("scroll", this.scroll, true);
  },
  methods: {
    // 下拉翻页加载数据
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          window.innerHeight;
        if (
          bottomOfWindow < 400 &&
          !this.loading &&
          this.list.length < this.total
        ) {
          this.currentPage++;
          if (this.activeIndex == 1) {
            this.getTenderList();
          } else if (this.activeIndex == 2) {
            this.getBidPriceList();
          } else {
            this.getWinNoticeList();
          }
        }
      };
    },
    /**
     * 点击tab栏切换
     * */

    handleClick(tab, event) {
      const { label, name } = tab;
      // if(this.activeIndex === name) return
      this.activeIndex = name;
      this.list = [];
      this.total = 0;
      this.form.type = "";
      this.form.state = "";
      this.form.verticalType = "";
      this.form.price = "";
      this.handleCurrentChange();
    },
    paramsChange(data) {
      data.provinceNo == this.form.provinceNo &&
        this.getMenuCount(data.provinceNo);
      this.form = data;
      this.list = [];
      this.handleCurrentChange();
    },
    handleCurrentChange(val = 1) {
      console.log(this.form, "this.form---");
      this.currentPage = val;
      this.getTenderType();
      this.getMoneyStep();
      this.searchList.forEach(item => {
        item.slist = [];
        // if(item.formKey === 'verticalType' || item.formKey === 'state') {
        //   item.slist = []
        // }
        if (+this.activeIndex === 3 && item.formKey === "price") {
          item.title = "中标金额";
        }
        if (
          (+this.activeIndex === 1 && item.formKey === "price") ||
          (+this.activeIndex == 2 && item.formKey === "price")
        ) {
          item.title = "概算金额";
        }
      });
      if (+this.activeIndex === 1) {
        this.getTenderList();
        this.getTenderState();
        this.getTenderMothed();
        return;
      }
      if (+this.activeIndex === 2) {
        this.getBidPriceList();
        return;
      }
      this.getWinNoticeList();
    },
    /**
     * 获取招标数量
     */
    async getMenuCount(provinceNo) {
      this.isReqCount = false;
      const pricepar = {
        state: "",
        name: "",
        city: "",
        areacode: provinceNo,
        cityNo: "",
        limit: 10,
        page: 1,
        price: "",
        type: "",
        keyword: localStorage.read("search_query")
      };
      const noticepar = {
        goodsType: "",
        limit: 10,
        page: 1,
        price: "",
        province: provinceNo,
        city: "",
        type: "",
        keyword: localStorage.read("search_query")
      };
      try {
        const res = await apiResSearch.getBidPriceList(pricepar);
        const { code, msg, total } = res.data;
        if (+code === 0) {
          this.tabText.bid = `竞价(${total})`;
        }
      } catch (error) {
        this.tabText.bid = `竞价(0)`;
      }
      try {
        const res = await apiResSearch.getWinNoticeList(noticepar);
        const { code, msg, total } = res.data;
        if (+code === 0) {
          this.tabText.notice = `中标公告(${total})`;
        }
      } catch (error) {
        this.tabText.notice = `中标公告(0)`;
      }
      // const res = await apiResSearch.getMenuCount({
      //   keyword: localStorage.read("search_query")
      // });
      // const { result, code, msg } = res.data;
      // if (+code === 0) {
      //   const {tenderCount,bidCount,bidNoticeCount} = result
      //   this.tabText.tend = `招标(${tenderCount})`
      //   this.tabText.bid = `竞价(${bidCount})`
      //   this.tabText.notice = `中标公告(${bidNoticeCount})`
      // }
    },
    /**
     * 招标方式
     */

    async getTenderMothed() {
      const res = await apiResSearch.getTenderMothed();
      const { result, code, msg } = res.data;
      if (+code === 0) {
        this.tenderMothed = Object.fromEntries(result.map(x => [x.no, x.name]));
        this.searchList.forEach(item => {
          if (item.formKey === "verticalType") {
            item.slist = [{ no: "", name: "不限" }, ...result];
          }
        });
      }
    },
    /**
     * 概算金额
     */

    async getMoneyStep() {
      const res = await apiResSearch.getMoneyStep();
      const { result, code, msg } = res.data;
      if (+code === 0) {
        this.searchList.forEach(item => {
          if (item.formKey === "price") {
            item.slist = result;
          }
        });
      }
    },
    /**
     * 招标状态
     */

    async getTenderState() {
      const res = await apiResSearch.getTenderState();
      const { result, code, msg } = res.data;
      if (+code === 0) {
        this.tenderState = Object.fromEntries(result.map(x => [x.no, x.name]));
        this.searchList.forEach(item => {
          if (item.formKey === "state") {
            item.slist = [{ no: "", name: "不限" }, ...result];
          }
        });
      }
    },
    /**
     * 获取招标类型列表
     */
    async getTenderType() {
      const res = await apiResSearch.getTenderType();
      const { result, code, msg } = res.data;
      if (+code === 0) {
        this.tenderType = Object.fromEntries(result.map(x => [x.no, x.name]));
        if (+this.activeIndex === 1 || +this.activeIndex === 3) {
          this.searchList.forEach(item => {
            if (item.formKey === "type") {
              item.slist = [{ no: "", name: "不限" }, ...result];
            }
          });
          return;
        }
        if (+this.activeIndex === 2) {
          const list = result.filter(x => [1, 2, 3, 4].includes(+x.no));
          this.searchList.forEach(item => {
            if (item.formKey === "type") {
              item.slist = [{ no: "", name: "不限" }, ...list];
            }
          });
        }
      }
    },
    /**
     * 获取中标公告列表
     */
    async getWinNoticeList() {
      this.loading = true;
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        type,
        price
      } = this.form;
      const parmas = {
        goodsType: "",
        limit: this.pageSize,
        page: this.currentPage,
        price: price,
        province: provinceNo,
        city: cityNo,
        type: type,
        keyword: localStorage.read("search_query")
      };
      const res = await apiResSearch.getWinNoticeList(parmas);
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.total = total > 999 ? 1000 : total;
        this.tabText.notice = `中标公告(${total})`;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.loading = false;
      }
      this.loading = false;
    },
    /**
     * 获取竞价列表
     */
    async getBidPriceList() {
      this.loading = true;
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        type,
        price
      } = this.form;
      const parmas = {
        state: "",
        name: "",
        city: "",
        areacode: provinceNo,
        cityNo: cityNo,
        limit: this.pageSize,
        page: this.currentPage,
        price: price,
        // total: 489,
        type: type,
        keyword: localStorage.read("search_query")
      };
      const res = await apiResSearch.getBidPriceList(parmas);
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.total = total > 999 ? 1000 : total;
        this.tabText.bid = `竞价(${total})`;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.loading = false;
      }
      this.loading = false;
    },
    /**
     * 获取招标列表
     */
    async getTenderList() {
      this.loading = true;
      const {
        cDisList = [],
        cityNo = "",
        provinceNo = "",
        type,
        price,
        state,
        verticalType
      } = this.form;
      const parmas = {
        time: "",
        // total: 6,
        biddingMethod: "",
        areacode: provinceNo,
        city: cityNo,
        keyword: localStorage.read("search_query"),
        limit: this.pageSize,
        page: this.currentPage,
        price: price,
        state: state,
        type: type,
        verticalType: verticalType
      };
      const res = await apiResSearch.getTenderList(parmas);
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.total = total > 999 ? 1000 : total;
        this.tabText.tend = `招标(${total})`;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.loading = false;
      }
      this.loading = false;
    }
  }
};
