// 集采方式-文本枚举
export const groupBuyTypeText = {
  1: { text: "即报即采", bg: "bg-17_28" },
  2: { text: "汇集采购", bg: "bg-f7_fe" },
};
// 集采方式-文本枚举
export const groupjoinTypeText = {
  1: { text: "会员加盟", bg: "bg-join-1" },
  2: { text: "市场加盟", bg: "bg-join-2" },
  3: { text: "成员加盟", bg: "bg-join-3" },
};
// 集采方式-文本枚举
export const groupOfferTypeText = {
  1: { text: "会员报价", bg: "bg-offer-1" },
  2: { text: "市场报价", bg: "bg-offer-2" },
  3: { text: "成员报价", bg: "bg-offer-3" },
};
// 集采状态-价格预警
export const groupBuyPriceColor = {
  // 集采状态_价格预警
  1: "car-6b",
  2: "car-00",
  3: "car-ff"
};
// 集采状态-文字
export const groupBuyStateText = {
  1: "集采中",
  2: "集采完成"
};
// 集采方式枚举
export const groupBuyTypeEnum = [
  {
    name: "全部",
    no: ""
  },
  {
    name: "汇集采购",
    no: "2"
  },
  {
    name: "即报即采",
    no: "1"
  }
];
// 集采类型枚举
export const groupbuyClassEnum = [
  {
    name: "全部",
    no: ""
  },
  {
    name: "商品材料",
    no: "1"
  },
  {
    name: "机械租赁",
    no: "2"
  },
  {
    name: "设备租赁",
    no: "3"
  }
];
// 集采状态
export const groupStateEnum = [
  {
    name: "全部",
    no: ""
  },
  {
    name: "集采中",
    no: "1"
  },
  {
    name: "集采完成",
    no: "2"
  }
  // {
  //   name: "集采失败",
  //   no: "4",
  // }
];
export const joinTypeEnum = [
  { name: "全部", no: "" },
  { name: "会员加盟", no: "1" },
  { name: "市场加盟", no: "2" },
  { name: "成员加盟", no: "3" }
];
export const offerTypeEnum = [
  { name: "全部", no: "" },
  { name: "会员报价", no: "1" },
  { name: "市场报价", no: "2" },
  { name: "成员报价", no: "3" }
];
